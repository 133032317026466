import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import logo from "../../assets/images/logo.png"
import Img from "gatsby-image"


import BoxFb from "../../assets/images/icons/bxl-facebook.svg"
import BoxInsta from "../../assets/images/icons/bxl-instagram.svg"
import BoxLinkedIn from "../../assets/images/icons/bxl-linkedin.svg"


import footerMap from "../../assets/images/footer-map.png"

// const Footer = (props) => {
//     const data = props.footer
//     const extra = props.extra

//     const currentYear = new Date().getFullYear();


export default function Footer(props) {
    const dataWp = props.footer
    const extra = props.extra

    const currentYear = new Date().getFullYear();
    return (
        <StaticQuery
            query={graphql`
                    query FooterImages {
                        footerMap: file(relativePath: { eq: "footer-map.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 693){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                `}
            render={data => (
                <footer className="footer-area bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="single-footer-widget">
                                    <a href="/" className="logo">
                                        <img src={logo} alt="logo" />
                                    </a>
                                    <p>{extra.content}</p>

                                    <ul className="social-link">
                                        <li>
                                            <a href="https://www.facebook.com/w3vitals" className="d-block" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-facebook'></i>
                                                <img src={BoxFb} style={{ width: "20px" }} alt="facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/w3vitals/" className="d-block" target="_blank" rel="noreferrer">
                                                <img src={BoxInsta} style={{ width: "20px" }} alt="instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/w3vitals" className="d-block" target="_blank" rel="noreferrer">
                                                <img src={BoxLinkedIn} style={{ width: "20px" }} alt="linked-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {(dataWp.menuItems && dataWp.menuItems.edges.length > 0) && dataWp.menuItems.edges.map((mi, i) => {
                                if (!mi.node.parentId) {
                                    return (
                                        <div className="col-lg-2 col-sm-6" key={`footer-list-${i}`}>
                                            <div className="single-footer-widget">
                                                <h3>{mi.node.label}</h3>
                                                <ul className="footer-links-list">
                                                    {mi.node.childItems && mi.node.childItems.edges.map((imi, j) => (
                                                        <li key={`footer-link-${j}`}>
                                                            <Link to={imi.node.url === "#" ? "/" : `/${imi.node.url.replace("http://", "")}/`}>
                                                                {imi.node.label}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                            <div className="col-lg-4 col-sm-6">
                                <div className="single-footer-widget">
                                    <h3>{extra.addressTitle}</h3>

                                    <div dangerouslySetInnerHTML={{ __html: extra.addressContent }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <p>Copyright @{currentYear} <strong>W3Vitals</strong> All rights reserved</p>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <ul>
                                        {/* <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-map" style={{ width: "40%" }}>
                        <Img fluid={data.footerMap.childImageSharp.fluid} alt="footer map" />
                    </div>
                </footer>
            )}
        />
    )
}
