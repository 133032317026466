import React from 'react'
// import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'
// import Promo from './Promo'
// import CookieConsent from "react-cookie-consent";


const Layout = ({ children, metaData }) => {
    return (
        <>
            {/* <CookieConsent
                location="bottom"
                buttonText="Αποδοχή"
                cookieName="cookieConsent"
                style={{ background: "#1f302d" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                Χρησιμοποιώντας αυτόν τον ιστότοπο, συμφωνείτε με την χρήση των cookies, σύμφωνα με τις οδηγίες μας.{" "}
                <span style={{ fontSize: "13px", color: "white" }}><a href="https://w3vitals/oroi-kai-proipotheseis/">Περισσότερα</a></span>
            </CookieConsent> */}
            <SEO metaData={metaData} />
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
            {/* { !isPromo && (
                <Promo />
            )} */}
    
        </>
    )
}

export default Layout
