import React, { useEffect, useState, Fragment } from 'react'
import { Helmet } from "react-helmet"

const SEO = (props) => {

    const [showGtm, setShowGtm] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowGtm(true)
        }, 5000)
    }, [])
    const metaData = props.metaData;
    return (
        <div>
            <Helmet>

                <html lang={metaData.locale} />
                <title>{metaData.title}</title>

{/* 
                {showGtm && (
                    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KRMPZQ6');</script>
                )} */}

                {/* {showGtm && (
                    <script async src='https://www.googletagmanager.com/gtag/js?id=GTM-KRMPZQ6'></script>
                )}

                {showGtm && (
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', GTM-KRMPZQ6);
                        `}
                    </script>
                )} */}

                {(metaData.noIndex && metaData.noIndex === "noindex") && (
                    <meta name="robots" content="noindex" />
                )}

                <meta name="description" content={metaData.description} />
                <link rel="canonical" href={metaData.canonical} />

                <meta property="og:title" content={metaData.fbTitle} />
                <meta property="og:description" content={metaData.fbDescription} />
                <meta property="og:url" content={metaData.canonical} />
                <meta property="og:type" content={metaData.fbType} />
                <meta property="og:locale" content={metaData.locale} />
                <meta property="og:image" content={metaData.image} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />

            </Helmet>
        </div >
    )
}

export default SEO
